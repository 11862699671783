import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'app/_services/user.service';
import { StripeScriptTag } from 'stripe-angular';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.css']
})
export class StripePaymentComponent implements OnInit {

  userInfo:any;
  subscriptionValidity:boolean;
  cardCaptureReady = false;
  startDate = new Date();
  endDate = new Date();
  terms:any;
  termsHtml:any
  successMsg:string = "Link Expired / Invalid Link";
  
  @BlockUI() blockUI: NgBlockUI;
  constructor(private route:ActivatedRoute, private userService:UserService, 
    private router:Router, private stripeScriptTag: StripeScriptTag) {
      if (!this.stripeScriptTag.StripeInstance) {
        this.userService.getStripeConfiguration().subscribe(resp=>{
          if(resp != null && resp.data.length > 0){
            let stripeKey = this.getValueByKey(resp.data,"public_key");
            this.stripeScriptTag.setPublishableKey(stripeKey);
          }
        });
      }
  }

  getValueByKey(list,key){
    let result = list.filter((el)=> el.configuration_name == key );
    return result.length > 0 ? result[0].configuration_value : null;
  }
  
  ngOnInit(): void {
    this.endDate.setFullYear(this.startDate.getFullYear() +1);

    this.route.params
    .subscribe(params => {
        this.userService.getTermsConditions(params.hash).subscribe(res=>{
          if(res != null && res.data.length > 0){
            this.termsHtml = res.data[0].configuration_value;
          }
        })
        this.userService.getUserbyHash(params.hash).subscribe(resp=>{
          if(resp.data.length){
            this.subscriptionValidity = true;
            this.userInfo = resp.data[0];
          } else {
            this.subscriptionValidity  = false;
          }
        },error=>{
          this.subscriptionValidity  = false;
        })
      }
    );
  }

  onStripeInvalid( error: Error ){
    console.log('Validation Error', error)
  }

  onStripeError( error: Error ){
    console.error('Stripe error', error)
  }

  setStripeToken( token ){
    this.blockUI.start('Processing...');
    const data = {customer_id:this.userInfo.stripe_cust_id, stripe_token:token.id, role:this.userInfo.role};
    this.userService.userStripeSubscription(data).subscribe( resp=> {
      this.subscriptionValidity  = false;
      this.successMsg = resp.message;
      this.blockUI.stop();
      setTimeout(()=>{
        this.router.navigate(['login']);
      },2000)
    });
  }

}
