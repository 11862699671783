import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgxNavigationWithDataComponent } from "ngx-navigation-with-data";
import {NgxMatTagInputModule} from 'ngx-mat-tag-input';
import {MatTabsModule} from '@angular/material/tabs';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';


import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { StripePaymentComponent } from './stripe-payment/stripe-payment.component';
import { StripeModule } from "stripe-angular"
import { ResendEmailComponent } from './resend-email/resend-email.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { BlockUIModule } from 'ng-block-ui';


let declarationsArray:any[]=[];
declarationsArray.push(AdminLayoutComponent);

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    MatTableModule,
    MatTabsModule,
    MatPaginatorModule,
    MatSortModule,
    AppRoutingModule,
    NgxMatTagInputModule,
    MatProgressSpinnerModule,
    BlockUIModule.forRoot(),
    StripeModule.forRoot(""),
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    ResendEmailComponent,
    LandingPageComponent,
    StripePaymentComponent
  ].concat(declarationsArray),
  providers: [authInterceptorProviders, NgxNavigationWithDataComponent],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
