// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl:'https://phpstack-614483-1992476.cloudwaysapps.com/',
  // apiUrl:'http://localhost:4000/',
  wpUrl:'https://wordpress-614483-1998932.cloudwaysapps.com/wp-json/',
  wpWeb: 'https://wordpress-614483-1998932.cloudwaysapps.com',
  wpUser:'webappuser',
  wpPass:'UW*!6kWDaWL!3z%cmAezgWGZ',
};