import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'app/_services/user.service';

@Component({
  selector: 'app-resend-email',
  templateUrl: './resend-email.component.html',
  styleUrls: ['./resend-email.component.css']
})
export class ResendEmailComponent implements OnInit {

  successMsg:string;
  errorMsg:string;
  matForm = this.fb.group({
    email: [null, [Validators.required]],
  });
  constructor( private fb:FormBuilder,private route:ActivatedRoute, private router:Router, private userService:UserService ) { }

  ngOnInit(): void {
    
  }

  onSubmit(){
    this.errorMsg = '';
    this.userService.sendPaymentEmail(this.matForm.value).subscribe(
      data => {
        this.successMsg = 'Email has been sent. Please click the link in the email.';
        this.matForm.reset();
      },
      err => {
        this.errorMsg = err.error.error;
        window.scroll(0,0);
      }
    )
  }

}
