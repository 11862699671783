import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { environment } from '../../environments/environment';
const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  registerMember(jsonObj): Observable<any> {
    return this.http.post(API_URL + 'users/member/create', jsonObj, { responseType: 'json' });
  }
  
  insertMemberDetails(jsonObj): Observable<any> {
    return this.http.post(API_URL + 'users/member/details', jsonObj, { responseType: 'json' });
  }

  insertMemberSuppliers(jsonObj): Observable<any> {
    return this.http.post(API_URL + 'users/member/current_suppliers', jsonObj, { responseType: 'json' });
  }

  insertMemberVendors(jsonObj): Observable<any> {
    return this.http.post(API_URL + 'users/member/current_vendors', jsonObj, { responseType: 'json' });
  }

  registerVendor(jsonObj): Observable<any> {
    return this.http.post(API_URL + 'users/vendor/create', jsonObj, { responseType: 'json' });
  }

  insertVendorDetails(jsonObj): Observable<any> {
    return this.http.post(API_URL + 'users/vendor/details', jsonObj, { responseType: 'json' });
  }

  updateMember(jsonObj): Observable<any> {
    return this.http.put(API_URL + 'users/member/'+jsonObj.id, jsonObj, { responseType: 'json' });
  }

  updateMemberDetails(jsonObj): Observable<any> {
    return this.http.put(API_URL + 'users/member/details/'+jsonObj.member_id, jsonObj, { responseType: 'json' });
  }

  updateMemberSuppliers(jsonObj): Observable<any> {
    return this.http.put(API_URL + 'users/member/current_suppliers/'+jsonObj.member_id, jsonObj, { responseType: 'json' });
  }

  updateMemberVendors(jsonObj): Observable<any> {
    return this.http.put(API_URL + 'users/member/current_vendors/'+jsonObj.member_id, jsonObj, { responseType: 'json' });
  }

  updateVendor(jsonObj): Observable<any> {
    return this.http.put(API_URL + 'users/vendor/'+jsonObj.id, jsonObj, { responseType: 'json' });
  }

  updateVendorDetails(jsonObj): Observable<any> {
    return this.http.put(API_URL + 'users/vendor/details/'+jsonObj.vendor_id, jsonObj, { responseType: 'json' });
  }

  getShareholders(): Observable<any> {
    return this.http.get(API_URL + 'users/shareholder', { responseType: 'json' });
  }

  getShareholderbyId(id:string): Observable<any> {
    return this.http.get(API_URL + 'users/shareholder/'+ id, { responseType: 'json' });
  }

  getMembers(): Observable<any> {
    return this.http.get(API_URL + 'users/member', { responseType: 'json' });
  }

  getMemberbyId(id): Observable<any> {
    return this.http.get(API_URL + 'users/member/'+ id, { responseType: 'json' });
  }

  getVendors(): Observable<any> {
    return this.http.get(API_URL + 'users/vendor', { responseType: 'json' });
  }

  getVendorbyId(id: string): Observable<any> {    
    return this.http.get(API_URL + 'users/vendor/' + id, { responseType: 'json' });
  }

  voteForUser(jsonObj): Observable<any> {
    return this.http.post(API_URL + 'users/vote', jsonObj, { responseType: 'json' });
  }

  getVoteOfUser(uid): Observable<any> {
    return this.http.get(API_URL + 'users/vote/'+uid, { responseType: 'json' });
  }

  updateShareholder(jsonObj): Observable<any> {    
    return this.http.put(API_URL + 'users/shareholder/'+jsonObj.id, jsonObj, { responseType: 'json' });
  }

  registerShareholder(jsonObj): Observable<any> {
    return this.http.post(API_URL + 'users/shareholder/', jsonObj, { responseType: 'json' });
  }

  resetPasswordRequest(jsonObj): Observable<any> {
    return this.http.post(API_URL + 'users/reset-password', jsonObj, { responseType: 'json' });
  }

  sendPaymentEmail(jsonObj): Observable<any> {
    return this.http.post(API_URL + 'users/send_payment_email', jsonObj, { responseType: 'json' });
  }

  resetPasswordValidate(jsonObj): Observable<any> {
    return this.http.post(API_URL + 'users/reset-password-validate', jsonObj, { responseType: 'json' });
  }

  getUserbyHash(hash): Observable<any> {
    return this.http.get(API_URL + 'users/detail?hash='+ hash, { responseType: 'json' });
  }

  userStripeSubscription(jsonObj): Observable<any> {
    return this.http.post(API_URL + 'users/subscription', jsonObj, { responseType: 'json' });
  }

  userStripeInvoices(jsonObj): Observable<any> {
    return this.http.post(API_URL + 'users/stripe_payment_history', jsonObj, { responseType: 'json' });
  }

  userStripeCards(jsonObj): Observable<any> {
    return this.http.post(API_URL + 'users/stripe_cards', jsonObj, { responseType: 'json' });
  }

  userStripeUpdateCard(jsonObj): Observable<any> {
    return this.http.post(API_URL + 'users/stripe_update_card', jsonObj, { responseType: 'json' });
  }

  userStripeCancelSubscription(jsonObj): Observable<any> {
    return this.http.post(API_URL + 'users/cancel_subscription', jsonObj, { responseType: 'json' });
  }

  getStripeConfiguration(): Observable<any> {
    return this.http.get(API_URL + 'users/getStripeConfiguration', { responseType: 'json' });
  }

  getAllVendorCategories(): Observable<any> {
    return this.http.get(API_URL + 'users/all-vendor-categories', { responseType: 'json' });
  }

  sendPaymentEmailToVendor(jsonObj): Observable<any> {
    return this.http.post(API_URL + 'users/vendor/'+jsonObj.id + '/send_payment_email', jsonObj, { responseType: 'json' });
  }

  sendPaymentEmailToMember(jsonObj): Observable<any> {
    return this.http.post(API_URL + 'users/member/'+jsonObj.id + '/send_payment_email', jsonObj, { responseType: 'json' });
  }

  getTermsConditions(hash): Observable<any> {
    return this.http.get(API_URL + 'users/getTermsConditions?hash='+ hash, { responseType: 'json' });
  }

  getUserById(id): Observable<any>{
    return this.http.get(API_URL + 'users/getUserById/'+ id, { responseType: 'json' })
  }

}