import { Component, OnInit } from "@angular/core";
import { AuthService } from "../_services/auth.service";
import { TokenStorageService } from "../_services/token-storage.service";
import { Router } from "@angular/router";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { UserService } from "app/_services/user.service";
import { environment } from "environments/environment";
import { DashboardService } from "app/_services/dashboard.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = "";
  wpURL: string = "";

  @BlockUI() blockUI: NgBlockUI;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private userService: UserService,
    private router: Router,
    private service: DashboardService,
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });

    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.router.navigate(["dashboard"]);
    }

    this.updateBlogLink();
  }

  updateBlogLink() {
    this.service.getSystemConfigByName("blog_url").subscribe((res)=>{
      this.wpURL = res["data"][0]["configuration_value"]
    })
  }

  onSubmit() {
    this.blockUI.start("Processing...");
    this.authService.login(this.loginForm.value).subscribe(
      (data) => {
        this.tokenStorage.saveToken(data.token);
        this.tokenStorage.saveUser(data);
        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.blockUI.stop();
        this.router.navigate(["dashboard"]);
      },
      (err) => {
        this.errorMessage = err.error.error;
        if (this.errorMessage == "Your account is awaiting payment") {
          this.sendEmail();
        }
        this.isLoginFailed = true;
        this.blockUI.stop();
      }
    );
  }

  sendEmail() {
    this.userService
      .sendPaymentEmail({ email: this.loginForm.value.username })
      .subscribe(
        (data) => {
          // this.successMsg = 'Email has been sent. Please click the link in the email.';
          // this.matForm.reset();
        },
        (err) => {
          // this.errorMsg = err.error.error;
          // window.scroll(0,0);
        }
      );
  }

  reloadPage(): void {
    window.location.reload();
  }
}
