import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'app/_services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  successMsg:string;
  errorMsg:string;
  reset_token:string;
  matForm = this.fb.group({
    email: [null, [Validators.required]],
  });
  constructor( private fb:FormBuilder,private route:ActivatedRoute, private router:Router, private userService:UserService ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.reset_token = params.reset_token;
      if(this.reset_token) {
        this.matForm = this.fb.group({
          password: [null, [Validators.required]],
          confirm_password: [null, [Validators.required]],
        });
      }
    });
  }

  onSubmit(){
    this.errorMsg = '';
    if (this.reset_token){
      var jsonObj = this.matForm.value;
      if(jsonObj.password === jsonObj.confirm_password){
        jsonObj.reset_token = this.reset_token;
        this.userService.resetPasswordValidate(jsonObj).subscribe(
          data => {
            this.successMsg = data.message;
            this.matForm.reset();
          },
          err => {
            this.errorMsg = err.error.error;
            window.scroll(0,0);
          }
        )
  
      } else {
        this.errorMsg = "Password did not match with confirm password"; 
      }

    } else {

      this.userService.resetPasswordRequest(this.matForm.value).subscribe(
        data => {
          this.successMsg = 'Your request has been submitted. Please check your email.';
          this.matForm.reset();
        },
        err => {
          this.errorMsg = err.error.error;
          window.scroll(0,0);
        }
      )
    }
  }

}
