import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getAwaitingMemeberList(): Observable<any> {
    return this.http.get(API_URL + 'dashboard/getAwaitingMemeberList', { responseType: 'json' });
  }
  getAwaitingVendorList(): Observable<any> {
    return this.http.get(API_URL + 'dashboard/getAwaitingVendorList', { responseType: 'json' });
  }
  getOffersListForMember(): Observable<any> {
    return this.http.get(API_URL + 'dashboard/getOffersListForMember', { responseType: 'json' });
  }
  getOfferRequestForVendor(): Observable<any> {
    return this.http.get(API_URL + 'dashboard/getOfferRequestForVendor', { responseType: 'json' });
  }

  getDasboardMembersStats(): Observable<any> {
    return this.http.get(API_URL + 'dashboard/getDasboardMembersStats', { responseType: 'json' });
  }
  getDasboardVendorsStats(): Observable<any> {
    return this.http.get(API_URL + 'dashboard/getDasboardVendorsStats', { responseType: 'json' });
  }
  getDasboardRocTotal(): Observable<any> {
    return this.http.get(API_URL + 'dashboard/getDasboardRocTotal', { responseType: 'json' });
  }
  getDasboardVendorRenewalCount(): Observable<any> {
    return this.http.get(API_URL + 'dashboard/getDasboardVendorRenewalCount', { responseType: 'json' });
  }
  
  getDasboardMemberRenewalCount(): Observable<any> {
    return this.http.get(API_URL + 'dashboard/getDasboardMemberRenewalCount', { responseType: 'json' });
  }  
  
  getDasboardMemberAmount(): Observable<any> {
    return this.http.get(API_URL + 'dashboard/getDasboardMemberAmount', { responseType: 'json' });
  }

  getDasboardMemberAmountSaved(): Observable<any> {
    return this.http.get(API_URL + 'dashboard/getDasboardMemberAmountSaved', { responseType: 'json' });
  }

  getSystemConfig(): Observable<any> {
    return this.http.get(API_URL + 'web/configuration', { responseType: 'json' });
  }

  getSystemConfigById(id): Observable<any> {
    return this.http.get(API_URL + 'web/configuration/'+id, { responseType: 'json' });
  }

  getSystemConfigByType(type): Observable<any> {
    return this.http.get(API_URL + 'web/getConfigurationByType/'+type, { responseType: 'json' });
  }

  getSystemConfigByName(name): Observable<any> {
    return this.http.get(API_URL + 'web/getConfigurationByName/'+name, { responseType: 'json' });
  }

  updateSystemConfig(id, jsonObj): Observable<any> {
    return this.http.put(API_URL + 'web/configuration/'+id, jsonObj, { responseType: 'json' });
  }

}