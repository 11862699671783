import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import {NgxMatTagInputModule} from 'ngx-mat-tag-input';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { StripePaymentComponent } from './stripe-payment/stripe-payment.component';
import { ResendEmailComponent } from './resend-email/resend-email.component';

const routes: Routes =[
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  }, 
  {
    path: '',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  // { path: 'home',   component: LandingPageComponent },
  { path: 'subscription/:hash',   component: StripePaymentComponent },
  { path: 'login',   component: LoginComponent },
  { path: 'reset-password',   component: ResetPasswordComponent },
  { path: 'resend-email',   component: ResendEmailComponent },
  { path: 'register/:type',   component: RegisterComponent },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    NgxMatTagInputModule,
    RouterModule.forRoot(routes,{
       useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
