import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from 'app/_services/dashboard.service';
import { EventService } from 'app/_services/event.service';
import { TokenStorageService } from 'app/_services/token-storage.service';
import { UserService } from 'app/_services/user.service';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ADMIN_ROUTES: RouteInfo[] = [  
    { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    { path: '/shareholders', title: 'Shareholders',  icon:'persons', class: '' },
    { path: '/members', title: 'Members',  icon:'persons', class: '' },
    { path: '/vendors', title: 'Vendors',  icon:'persons', class: '' },
    { path: '/categories', title: 'Categories',  icon:'category', class: '' },
    { path: '/vendor-offers', title: 'Vendor Offers',  icon:'card_giftcard', class: '' },
    { path: '/offer-requests', title: 'Requested Quotes',  icon:'card_membership', class: '' },
    { path: '/blog', title: 'Blog Posts',  icon:'description', class: '' },
    { path: '/blog-categories', title: 'Blog Categories',  icon:'subject', class: '' },
    { path: '/email-templates', title: 'Email Templates',  icon:'mail', class: '' },
    { path: '/reports', title: 'Reports',  icon:'stacked_bar_chart', class: '' },
    { path: '/settings', title: 'Settings',  icon:'settings', class: '' },


    /*
    { path: '/user-profile', title: 'User Profile',  icon:'person', class: '' },
    { path: '/table-list', title: 'Table List',  icon:'content_paste', class: '' },
    { path: '/typography', title: 'Typography',  icon:'library_books', class: '' },
    { path: '/icons', title: 'Icons',  icon:'bubble_chart', class: '' },
    { path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
    { path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },
    */
];
export const VENDOR_ROUTES: RouteInfo[] = [  
  { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
  // { path: '/members', title: 'Members',  icon:'persons', class: '' },
  { path: '/vendor-offers', title: 'Vendor Offers',  icon:'card_giftcard', class: '' },
  { path: '/offer-requests', title: 'Requested Quotes',  icon:'card_membership', class: '' },
  { path: '/vendor-questions', title: 'Questions',  icon:'question_answer', class: '' },
  { path: '/sales-reports', title: 'Sales Reports',  icon:'equalizer', class: '' },
];
export const MEMBER_ROUTES: RouteInfo[] = [  
  { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
  { path: '/vendors-list', title: 'Vendors',  icon:'persons', class: '' },
  { path: '/vendor-offers', title: 'Vendor Offers',  icon:'card_giftcard', class: '' },
  { path: '/offer-requests', title: 'Requested Quotes',  icon:'card_membership', class: '' },

  /*
  { path: '/user-profile', title: 'User Profile',  icon:'person', class: '' },
  { path: '/table-list', title: 'Table List',  icon:'content_paste', class: '' },
  { path: '/typography', title: 'Typography',  icon:'library_books', class: '' },
  { path: '/icons', title: 'Icons',  icon:'bubble_chart', class: '' },
  { path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
  { path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },
  */
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  authUser:any;
  stripeKey: string;
  menuName: string
  menuLink: string
  blogLink: string
  role: string;
  subscriber: any;
  constructor(private tokenStorage:TokenStorageService, private eventService: EventService, private dashboardService: DashboardService, private router:Router, private userService: UserService) { }

  ngOnInit() {
    this.subscriber = this.eventService.getEvent().subscribe((res)=>{
      if(res.event == "update_menu"){
        this.authUser = this.tokenStorage.getUser();
        this.updateFAQMenu(this.authUser.role);
        this.updateBlogLink();
      }
    })
    

    this.userService.getStripeConfiguration().subscribe(resp=>{
      if(resp != null && resp.data.length > 0){
        this.stripeKey = this.getValueByKey(resp.data,"public_key");
      }
    });

    if (this.tokenStorage.getUser()) {
      this.authUser = this.tokenStorage.getUser()
      if(this.authUser.role === 'vendor'){
        this.menuItems = VENDOR_ROUTES.filter(menuItem => menuItem);
      } else if(this.authUser.role === 'member'){
        this.menuItems = MEMBER_ROUTES.filter(menuItem => menuItem);
      } else {
        this.menuItems = ADMIN_ROUTES.filter(menuItem => menuItem);
      }
    }
    this.updateFAQMenu(this.authUser.role)
    this.updateBlogLink();

  }

  updateBlogLink() {
    this.dashboardService.getSystemConfigByName("blog_url").subscribe((res)=>{
      this.blogLink = res["data"][0]["configuration_value"]
    })
  }

  updateFAQMenu(role){
    if(role === 'vendor'){
      this.dashboardService.getSystemConfigByName("faq_label_for_vendor").subscribe((res)=>{
        this.menuName = res["data"][0]["configuration_value"]
      })
      this.dashboardService.getSystemConfigByName("faq_link_for_vendor").subscribe((res)=>{
        this.menuLink = res["data"][0]["configuration_value"]
      })
    } else if(role === 'member'){
      this.dashboardService.getSystemConfigByName("faq_label_for_member").subscribe((res)=>{
        this.menuName = res["data"][0]["configuration_value"]
      })
      this.dashboardService.getSystemConfigByName("faq_link_for_member").subscribe((res)=>{
        this.menuLink = res["data"][0]["configuration_value"]
      })
    } else {
      this.dashboardService.getSystemConfigByName("faq_label_for_shareholder").subscribe((res)=>{
        this.menuName = res["data"][0]["configuration_value"]
      })
      this.dashboardService.getSystemConfigByName("faq_link_for_shareholder").subscribe((res)=>{
        this.menuLink = res["data"][0]["configuration_value"]
      })
    }
  }
  
  getValueByKey(list,key){
    let result = list.filter((el)=> el.configuration_name == key );
    return result.length > 0 ? result[0].configuration_value : null;
  }

  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
  logout(){
    this.tokenStorage.signOut();
    this.router.navigate(['/']);
  }

  goToProfile() {
    this.router.navigate(['user-profile'], { state: { stripeKey: this.stripeKey } })
  }

}
