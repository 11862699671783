import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {ErrorStateMatcher} from '@angular/material/core';
import { UserService } from 'app/_services/user.service';
import { environment } from 'environments/environment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MustMatch } from 'app/_helpers/must-match.validator';
import { DashboardService } from 'app/_services/dashboard.service';
import Swal from 'sweetalert2'
import { EventService } from 'app/_services/event.service';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  member_intro:string;
  vendor_intro:string;
  accountType:string;
  matForm: FormGroup;
  matFormMember2: FormGroup;
  matFormMember3: FormGroup;
  matFormMember4: FormGroup;
  matFormVendor2: FormGroup;
  matcher = new MyErrorStateMatcher();
  errorMessage:string;
  successMessage:string;
  formStep:number=1;
  vendorStepTitle:string = 'Register'
  memberStepTitle:string = 'Register'
  user_id:number;
  loading:boolean = true;
  wpURL: string = "";

  @BlockUI() blockUI: NgBlockUI;
  subscriber: any;
  constructor(private activeRoute:ActivatedRoute,private eventService: EventService, private fb:FormBuilder, private userService:UserService, private service: DashboardService) {
    this.activeRoute.params.subscribe(params => {
      this.accountType=params['type'];
    });

    this.loadSignUpForm();

    this.loadConfigs();

    // Vendor Forms
    this.matFormVendor2 = this.fb.group({
      vendor_id:['',[]],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      postal_code: ['', [Validators.required]],
      website: ['', []],
      category: ['', []],
      experience: ['', []],
      referring_member: ['', []],
      professional_reference_1: ['', []],
      professional_reference_2: ['', []],
    });

    // Member Forms
    this.matFormMember2 = this.fb.group({
      member_id:['', []],
      restaurant_name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      postal_code: ['', [Validators.required]],
      owner_name: ['', [Validators.required]],
      year_established: ['', [Validators.required]],
      yearly_gross_sales: ['', [Validators.required]],
      number_of_employees: ['', [Validators.required]],
      own_or_rent_property: ['', []],
      landlord_name: ['', []],
      avg_cost_food: ['', []],
      avg_cost_of_liquor: ['', []],
      avg_cost_of_wine: ['', []],
      avg_cost_of_labor: ['', []],
    });
    
    this.matFormMember3 = this.fb.group({
      member_id:['', []],
      major_food_distributor: ['', []],
      non_alcoholic_beverages: ['', []],
      coffee: ['', []],
      bear: ['', []],
      wine: ['', []],
      liquor: ['', []],
      other_supplier: ['', []],
    });

    this.matFormMember4 = this.fb.group({
      member_id:['', []],
      credit_card_processor: ['', []],
      pos_system: ['', []],
      insurance: ['', []],
      linen_company: ['', []],
      utilities_cos: ['', []],
      social_media_cos: ['', []],
      delivery_cos: ['', []],
      banking: ['', []],
      legal: ['', []],
      accounting: ['', []],
      payroll: ['', []],
      printer: ['', []],
      information_technology: ['', []],
      graphic_designer: ['', []],
      equipment: ['', []],
      cleaning: ['', []],
      marketing: ['', []],
      hiring: ['', []],
      financial: ['', []],
      valet: ['', []],
      plumber: ['', []],
      real_estate_broker: ['', []],
    });

    
  }

  ngOnInit(): void {
    this.subscriber = this.eventService.getEvent().subscribe((res)=>{
      if(res.event == "update_menu"){
        this.updateBlogLink();
      }
    })
    this.updateBlogLink();
  }

  updateBlogLink() {
    this.service.getSystemConfigByName("blog_url").subscribe((res)=>{
      this.wpURL = res["data"][0]["configuration_value"]
    })
  }

  loadConfigs(){

    this.service.getSystemConfigByType('registration_intro').subscribe( resp=>{     
      let configs = resp['data'];
      configs.forEach(c => {
        if (c.configuration_name == "vendor_registration_intro"){
          this.vendor_intro = c.configuration_value;
        }
        if (c.configuration_name == "member_registration_intro"){
          this.member_intro = c.configuration_value;
        }
      });

    },error=>{
      console.log(error);
    })
  }
  loadSignUpForm() {
    this.matForm = this.fb.group({
			first_name: ['', [Validators.required]],
			last_name: ['', [Validators.required]],
			company: ['', [Validators.required]],
			phone: ['', []],
			username: ['', [Validators.required, Validators.minLength(8)]],
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required, Validators.minLength(8)]],
      role:[this.accountType, []],
      image:[],
      confirmPassword: ['',[Validators.required]]
      }, {
        validator: MustMatch('password', 'confirmPassword')
		  }); 
  }

  signUp():void {
    this.errorMessage = '';
    if (this.matForm.valid){
      if(this.accountType == 'member'){
        this.memberStepTitle = 'Member Details';
        this.formStep = 2;
      }
      else if(this.accountType == 'vendor'){
        this.vendorStepTitle = 'Vendor Details';
        this.formStep = 2;
      }

    } else {
      console.log(this.matForm.value);
    }
  }

  vendorStepFinal() {
    this.blockUI.start('Processing...');
    this.userService.registerVendor(this.matForm.value).subscribe(
      data => {
        this.user_id = data.user.id;
        this.matFormVendor2.controls['vendor_id'].setValue(this.user_id);
        this.userService.insertVendorDetails(this.matFormVendor2.value).subscribe(
          data => {
            this.matForm.reset();
            Swal.fire(
              'Vendor SignUp Completed',
              'Thank you for your application, we will process it as soon as possible.',
              'success'
            )
            this.successMessage = 'Thank you for your application, we will process it as soon as possible.'        
            this.vendorStepTitle = 'Vendor SignUp Completed';
            this.blockUI.stop();
            this.formStep = 1;
            this.loadSignUpForm();
            setTimeout(()=>{
              window.location.href = this.wpURL;
            }, 5000);
          },
          err => {
            this.errorMessage = err.error.message;
            window.scroll(0,0);
            this.blockUI.stop();
          }
        );

      },
      err => {
        this.errorMessage = err.error.message;
        window.scroll(0,0);
        this.formStep = 1;
        this.blockUI.stop();
      }
    );
  }

  memberStepTwo() {
    this.formStep = 3;
  }

  memberStepThree() {
    this.formStep = 4;
  }

  memberStepFinal() {
    this.blockUI.start('Processing...');
    this.userService.registerMember(this.matForm.value).subscribe(
      data => {
        this.user_id = data.user.id;
        this.matFormMember2.controls['member_id'].setValue(this.user_id);
        this.matFormMember3.controls['member_id'].setValue(this.user_id);
        this.matFormMember4.controls['member_id'].setValue(this.user_id);
    
        this.userService.insertMemberDetails(this.matFormMember2.value).subscribe(
          data => {
            this.userService.insertMemberSuppliers(this.matFormMember3.value).subscribe(
              data => {
                this.userService.insertMemberVendors(this.matFormMember4.value).subscribe(
                  data => {
                    Swal.fire(
                      'Member SignUp Completed',
                      'Thank you for your application, we will process it as soon as possible.',
                      'success'
                    )
                    this.successMessage = 'Thank you for your application, we will process it as soon as possible.'        
                    this.memberStepTitle = 'Member SignUp Completed';
                    this.formStep = 1;
                    this.blockUI.stop();
                    this.matForm.reset();
                    this.loadSignUpForm();
                    setTimeout(()=>{
                      window.location.href = this.wpURL;
                    }, 5000);
                  },
                  err => {
                    this.errorMessage = err.error.message;
                    window.scroll(0,0);
                    this.formStep = 1;
                    this.blockUI.stop();
                  }
                );
              },
              err => {
                this.errorMessage = err.error.message;
                window.scroll(0,0);
                this.formStep = 1;
                this.blockUI.stop();
              }
            );
          },
          err => {
            this.errorMessage = err.error.message;
            window.scroll(0,0);
            this.formStep = 1;
            this.blockUI.stop();
          }
        );        
      },
      err => {
        this.errorMessage = err.error.message;
        window.scroll(0,0);
        this.formStep = 1;
        this.blockUI.stop();
      }
    );


  }

  matformHasError(controlName: string, errorName: string) {
    return this.matForm.controls[controlName].hasError(errorName);
  }

  matFormVendorHasError(controlName: string, errorName: string) {
    return this.matFormVendor2.controls[controlName].hasError(errorName);
  }

  matFormMemberHasError(controlName: string, errorName: string) {
    return this.matFormMember2.controls[controlName].hasError(errorName);
  }

}

