import { Component, OnInit, ElementRef } from '@angular/core';
import { ADMIN_ROUTES, VENDOR_ROUTES, MEMBER_ROUTES } from '../sidebar/sidebar.component';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../_services/token-storage.service';
import { UserService } from 'app/_services/user.service';
import { environment } from 'environments/environment';
import { DashboardService } from 'app/_services/dashboard.service';
import { EventService } from 'app/_services/event.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

    authUser:any;
    location: Location;
    mobile_menu_visible: any = 0;
    stripeKey: string;
    blogLink: string = "";
    
    private listTitles: any[];
    private toggleButton: any;
    private sidebarVisible: boolean;
    subscriber: any;

    constructor(location: Location,private eventService: EventService, private dashboardService: DashboardService, private userService: UserService, private element: ElementRef, private router: Router, private tokenStorage:TokenStorageService) {
      this.location = location;
          this.sidebarVisible = false;
    }

    ngOnInit(){
        this.subscriber = this.eventService.getEvent().subscribe((res)=>{
            if(res.event == "update_menu"){
                this.updateBlogLink();
            }
        })

        this.userService.getStripeConfiguration().subscribe(resp=>{
            if(resp != null && resp.data.length > 0){
              this.stripeKey = this.getValueByKey(resp.data,"public_key");
            }
        });
        this.updateBlogLink();

        if (this.tokenStorage.getUser()) {
            this.authUser = this.tokenStorage.getUser()
            switch(this.authUser.role){
                case 'shareholder':
                    this.listTitles = ADMIN_ROUTES.filter(listTitle => listTitle);
                    break;
                case 'vendor':
                    this.listTitles = VENDOR_ROUTES.filter(listTitle => listTitle);
                    break;
                case 'member':
                    this.listTitles = MEMBER_ROUTES.filter(listTitle => listTitle);
                    break;
            }
        }

        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.router.events.subscribe((event) => {
            this.sidebarClose();
            var $layer: any = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
            $layer.remove();
            this.mobile_menu_visible = 0;
            }
        });
    }

    updateBlogLink() {
        this.dashboardService.getSystemConfigByName("blog_url").subscribe((res)=>{
          this.blogLink = res["data"][0]["configuration_value"]
        })
    }

    getValueByKey(list,key){
        let result = list.filter((el)=> el.configuration_name == key );
        return result.length > 0 ? result[0].configuration_value : null;
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);

        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        const body = document.getElementsByTagName('body')[0];

        if (this.mobile_menu_visible == 1) {
            // $('html').removeClass('nav-open');
            body.classList.remove('nav-open');
            if ($layer) {
                $layer.remove();
            }
            setTimeout(function() {
                $toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function() {
                $toggle.classList.add('toggled');
            }, 430);

            var $layer = document.createElement('div');
            $layer.setAttribute('class', 'close-layer');


            if (body.querySelectorAll('.main-panel')) {
                document.getElementsByClassName('main-panel')[0].appendChild($layer);
            }else if (body.classList.contains('off-canvas-sidebar')) {
                document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
            }

            setTimeout(function() {
                $layer.classList.add('visible');
            }, 100);

            $layer.onclick = function() { //asign a function
              body.classList.remove('nav-open');
              this.mobile_menu_visible = 0;
              $layer.classList.remove('visible');
              setTimeout(function() {
                  $layer.remove();
                  $toggle.classList.remove('toggled');
              }, 400);
            }.bind(this);

            body.classList.add('nav-open');
            this.mobile_menu_visible = 1;

        }
    };

    getTitle(){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }

      for(var item = 0; item < this.listTitles.length; item++){
          if(this.listTitles[item].path === titlee){
              return this.listTitles[item].title;
          }
      }
      return '';
    }

    logout(){
        this.tokenStorage.signOut();
        // this.router.navigate(['/']);
        this.router.navigate(["/"]).then(result=>{window.location.href = this.blogLink;});

    }

    goToProfile() {
        this.router.navigate(['user-profile'], { state: { stripeKey: this.stripeKey } })
    }
}
