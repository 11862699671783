import { HTTP_INTERCEPTORS, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { TokenStorageService } from '../_services/token-storage.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { tap } from "rxjs/operators";
import { Router } from '@angular/router';

const WP_URL = environment.wpUrl;
const API_URL = environment.apiUrl;
const WP_USER = environment.wpUser;
const WP_PASS = environment.wpPass;


const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private token: TokenStorageService, private router:Router, private tokenStorage:TokenStorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.token.getToken();
    const wp_token = this.token.getWPToken();

    
    if (token != null && req.url.includes(API_URL)) {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
    }

    if (req.url.includes(WP_URL)) {
      let wp_token = btoa(WP_USER+':'+WP_PASS); 
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Basic '+wp_token) });
    }
    return next.handle(authReq).pipe(
      tap((event: HttpEvent<any>) => {
        
        if (event instanceof HttpResponse && event.status === 401) {
          this.tokenStorage.signOut();
          this.router.navigate(['/']);
        }
      })
    );    
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];